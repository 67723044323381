<template>
  <div>
    <h2 v-html="$t('production_form_search_title')"></h2>
    <!-- Status -->
    <v-select
      id="status"
      v-model="searchCardStore.status"
      :label="$t('production_form_search_status')"
      :items="statuses"
      item-value="name"
      :item-title="this.$getLangKey()"
      clearable
      @keyup.enter="search"
    />
    <!-- Tierart -->
    <v-select
      id="animalType"
      required
      v-model="searchCardStore.animalTypeId"
      :items="animalTypesProduction"
      item-value="id"
      :item-title="this.$getLangKey()"
      :label="$t('production_form_search_animalTypes')"
      clearable
      @keyup.enter="search"
    />
    <!-- Periode von -->
    <DbmMonthPicker
      id="production_form_search_period"
      v-model="searchCardStore.period"
      :dateLabel="$t('processing_form_read_search_year_month_until')"
      :rules="[$rules.isCHMonth, $rules.required]"
    />
    <!--     Monat -->
    <v-select
      id="display"
      required
      v-model="searchCardStore.loadPreviousMonths"
      :items="loadPreviousMonthsOptions"
      item-value="value"
      item-title="text"
      :label="$t('production_form_search_display')"
      @keyup.enter="search"
    ></v-select>
    <!-- expanded-->
    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <v-text-field
        name="amountFrom"
        :label="$t('production_form_search_amount_from')"
        v-model="searchCardStore.quantityFrom"
        @keyup.enter="search"
      ></v-text-field>
      <v-text-field
        name="amountTill"
        :label="$t('production_form_search_amount_till')"
        v-model="searchCardStore.quantityTo"
        @keyup.enter="search"
      ></v-text-field>
      <DbmMonthPicker
        id="production_form_search_created_from"
        v-model="searchCardStore.createdFrom"
        :dateLabel="$t('production_form_search_created_from')"
        :clearable="true"
      />
      <DbmMonthPicker
        id="production_form_search_created_until"
        v-model="searchCardStore.createdUntil"
        :dateLabel="$t('production_form_search_created_until')"
        :clearable="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { apiURL, app } from '../../main'

import { showError } from '@/services/axios'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'
import { useSearchProductionOverviewStore } from '@/store/SearchProductionOverviewStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchProductionOverview',
  extends: baseSearchVue,
  components: { DbmMonthPicker },
  data() {
    return {
      expanded: false,
      app: app,
      statuses: [],
      loadPreviousMonthsOptions: [
        {
          text: this.$t('production_form_read_display_current_month'),
          value: 'PREVIOUS'
        },
        {
          text: this.$t('production_form_read_display_start_of_year'),
          value: 'START_OF_THE_YEAR'
        },
        {
          text: this.$t('production_form_read_display_last_twelve_months'),
          value: 'TWELVE_MONTHS'
        }
      ]
    }
  },
  computed: {
    animalTypesProduction() {
      return useAnimalTypesStore().AnimalTypesProduction
    }
  },
  methods: {
    async getProductionFormStatuses() {
      try {
        const result = await this.axios.get(apiURL + '/productionFormRows/status', {}, { headers: { 'Content-Type': 'application/json' } })
        this.statuses = result.data
      } catch (e) {
        showError(e)
      }
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchProductionOverviewStore(), true)
    useAnimalTypesStore().fetchAnimalTypesProduction()
    this.getProductionFormStatuses()
  }
})
</script>
