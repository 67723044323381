import { TermItemDefinition } from '@/services/term'
import { format, startOfMonth, addMonths } from 'date-fns'
import { defineStore } from 'pinia'
export const useSearchProductionOverviewStore = defineStore('searchProductionOverviewStore', {
  state: () => ({
    status: null as string | null,
    animalTypeId: null as number | null,
    loadPreviousMonths: null as string | null,
    quantityFrom: null as number | null,
    quantityTo: null as number | null,
    createdFrom: null as string | null,
    createdUntil: null as string | null,
    countRemarks: null as number | null,
    countFiles: null as number | null,
    period: format(addMonths(startOfMonth(new Date()), -1), 'yyyy-MM-dd') as string | null,
    items: [
      { key: 'status', operation: ':', itemType: 'string' },
      { key: 'animalTypeId', operation: ':', itemType: 'number' },
      { key: 'period', operation: ':', itemType: 'string', defaultValue: format(addMonths(startOfMonth(new Date()), -1), 'yyyy-MM-dd') },
      { key: 'loadPreviousMonths', operation: ':', itemType: 'string' },
      { key: 'quantityFrom', operation: ':' },
      { key: 'quantityTo', operation: ':' },
      { key: 'createdFrom', operation: ':' },
      { key: 'createdUntil', operation: ':' },
      { key: 'countRemarks', operation: ':' },
      { key: 'countFiles', operation: ':' }
    ] as TermItemDefinition[]
  })
})
